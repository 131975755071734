import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default function Page(props) {
  const { node } = props.data.allContentfulPage.edges[0];
  return (
    <main>
      <title>Dita Basu - {node.name ? node.name : ""}</title>
      <Layout page={props.params.link} content={node}></Layout>
    </main>
  );
}

export const query= graphql` 
                    query($id: String)    {
                            allContentfulPage(filter: {id: {eq: $id}}) {
                                edges {
                                  node {
                                    name
                                    maintext {
                                      raw
                                      references {
                                        ... on ContentfulAsset {
                                          contentful_id
                                          gatsbyImageData
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                        }
                    `
                    